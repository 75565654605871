const roomSizeStatic = {
  CC: {
    US: {
      filter: '790 ft<sup>2</sup>',
      dander: '1,007 ft<sup>3</sup>',
    },
    CA: {
      filter: '73.4 m<sup>2</sup>',
      dander: ' 28.5 m<sup>3</sup>',
    },
    GB: {
      filter: '73.4 m<sup>2</sup>',
      dander: '28.5 m<sup>3</sup>',
    },
    AU: {
      filter: '73.4 m<sup>2</sup>',
      dander: '28.5 m<sup>3</sup>',
    },
    SG: {
      filter: '73.4 m<sup>2</sup>',
      dander: '28.5 m<sup>3</sup>',
    },
    EU: {
      filter: '73.4 m<sup>2</sup>',
      dander: '28.5 m<sup>3</sup>',
    },
  },
};

const price = {
  CC: {
    US: {
      unit: '349',
      filter: '79',
    },
    CA: {
      unit: '470',
      filter: '110',
    },
    GB: {
      unit: '309',
      filter: '70',
    },
    AU: {
      unit: '548',
      filter: '110',
    },
    SG: {
      unit: '475',
      filter: '104',
    },
    EU: {
      unit: '349',
      filter: '79',
    },
  },
};

export { roomSizeStatic, price };
